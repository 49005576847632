import React from 'react'
import styled from '@emotion/styled'

const ErrorContainer = styled.div`
  width: 100%;
  padding-top: 200px;
  min-height: 300px;
`

const NotFound = () => (
  <ErrorContainer>
    <div className="container-fluid">
      <div className="row">
        <div className="col">
          <h1>Strona nie istnieje</h1>
          <p>Ups, nie znalezliśmy strony pod tym adresem!</p>
        </div>
      </div>
    </div>
  </ErrorContainer>
)

export default NotFound
